<template>
  <div>
    <div class="container">
      <div class="itemTitle">表1   一企一档基本信息</div>
      <div class="itemContent">
        <div class="column"><span>企业名称:</span>{{companyInformation.companyName}}</div>
        <div class="column"><span>统一社会信用代码:</span>{{companyInformation.creditCode}}</div>
        <div class="column"><span>工商注册详细地址:</span>{{companyInformation.businessRegistrationDetailedAddress}}</div>
        <div class="column"><span>工商所在地:</span>{{companyInformation.businessLocation}}</div>
        <div class="column"><span>生产设施地址:</span>{{companyInformation.productionFacilityAddress}}</div>
        <div class="column"><span>企业法人:</span>{{companyInformation.enterpriseLegalPerson}}</div>
        <div class="column"><span>年产危废规模:</span>{{companyInformation.annualProductionScale}}</div>
        <div class="column"><span>管辖环保局:</span>{{companyInformation.responsibleProtectionBureau}}</div>
        <div class="column"><span>营业执照:</span><img :src="companyInformation.businessLicense" v-if="companyInformation.businessLicense" style="width:100%;height:50%" ></div>
        <div class="column"><span>注册资金（万元）:</span>{{companyInformation.registeredCapital}}</div>
        <div class="column"><span>面积（万平方）:</span>{{companyInformation.area}}</div>
        <div class="column"><span>环保负责人:</span>{{companyInformation.superintendent}}</div>
        <div class="column"><span>联系电话:</span>{{companyInformation.telephone}}</div>
        <div class="column"><span>传真:</span>{{companyInformation.fax}}</div>
        <div class="column"><span>邮箱:</span>{{companyInformation.email}}</div>
      </div>
    </div>

    <div class="container">
      <div class="itemTitle">表2  企业产废信息</div>
      <div class="itemContent" v-for="item in companyWasteList" :key="item.id">
        <div class="column"><span>危废大类:</span>{{item.hazardousCategory}}</div>
        <div class="column"><span>八位码:</span>{{item.eightDigitCode}}</div>
        <div class="column"><span>俗称:</span>{{item.commonCalled}}</div>
        <div class="column"><span>产废形态:</span>{{item.hazardousForm}}</div>
        <div class="column"><span>危废特性:</span>{{item.hazardousCharacteristic}}</div>
        <div class="column"><span>有害成份:</span>{{item.hazardousIngredients}}</div>
        <div class="column"><span>本年产生（吨）:</span>{{item.yearGenerateTon}}</div>
        <div class="column"><span>本年产生（只）:</span>{{item.yearGeneratePiece}}</div>
      </div>
    </div>

<!--    <div class="container">
      <table>
        <thead>
        <tr>
          <th colspan="8" class="itemTitle">表2  企业产废信息</th>
        </tr>
        </thead>
        <tr>
          <td style="text-align:center;width: 10%;">危废大类</td>
          <td style="text-align:center;width: 10%;">八位码</td>
          <td style="text-align:center;width: 10%;">俗称</td>
          <td style="text-align:center;width: 10%;">产废形态</td>
          <td style="text-align:center;width: 10%;">危废特性</td>
          <td style="text-align:center;width: 10%;">有害成份</td>
          <td style="text-align:center;width: 10%;">本年产生<br/>（吨）</td>
          <td style="text-align:center;width: 10%;">本年产生<br/>（只）</td>
        </tr>
        <tbody>
        <tr v-for="item in companyWasteList" :key="item.id">
          <td>{{ item.hazardousCategory }}</td>
          <td>{{ item.eightDigitCode }}</td>
          <td>{{ item.commonCalled }}</td>
          <td>{{ item.hazardousForm }}</td>
          <td>{{ item.hazardousCharacteristic }}</td>
          <td>{{ item.hazardousIngredients }}</td>
          <td>{{ item.yearGenerateTon }}</td>
          <td>{{ item.yearGeneratePiece }}</td>
        </tr>
        </tbody>
      </table>
    </div>-->

    <div class="container">
      <div  class="itemTitle">表3  年度出入库信息表</div>
      <div class="itemContent" v-for="item in companyInventoryList" :key="item.id">
        <div class="column"><span>危废大类:</span>{{item.hazardousCategory}}</div>
        <div class="column"><span>八位码:</span>{{item.eightDigitCode}}</div>
        <div class="column"><span>俗称:</span>{{item.commonCalled}}</div>
        <div class="column"><span>产废形态:</span>{{item.hazardousForm}}</div>
        <div class="column"><span>危废特性:</span>{{item.hazardousCharacteristic}}</div>
        <div class="column"><span>有害成份:</span>{{item.hazardousIngredients}}</div>
        <div class="column"><span>入库时间:</span>{{item.storageTime}}</div>
        <div class="column"><span>入库量（吨）:</span>{{item.storageQuantity}}</div>
        <div class="column"><span>出库时间:</span>{{item.stockOutTime}}</div>
        <div class="column"><span>出库量（吨）:</span>{{item.stockOutQuantity}}</div>
        <div class="column"><span>库存数量:</span>{{item.inventoryQuantity}}</div>
      </div>
    </div>

    <div class="container">
      <div class="itemTitle">表4  企业贮存点</div>
      <div class="itemContent" v-for="item in companyStorageList" :key="item.id">
        <div class="column"><span>贮存点:</span>{{item.storagePoint}}</div>
        <div class="column"><span>贮存点类型:</span>{{item.storageType}}</div>
        <div class="column"><span>面积:</span>{{item.storageArea}}</div>
        <div class="column"><span>贮存能力:</span>{{item.storageCapacity}}</div>
      </div>
    </div>

    <div class="container">
      <div class="itemTitle">表5  委托利用和处置情况</div>
      <div class="itemContent" v-for="(item, index) in companyEntrustList" :key="item.id">
        <div class="column"><span>序号:</span>{{index+1}}</div>
        <div class="column"><span>危险废物名称:</span>{{item.hazardousName}}</div>
        <div class="column"><span>废物代码:</span>{{item.hazardousCode}}</div>
        <div class="column"><span>委托经营单位名称:</span>{{item.entrustedCompany}}</div>
        <div class="column"><span>许可证编码:</span>{{item.licenseCode}}</div>
        <div class="column"><span>本年度计划委托利用处置量:</span>{{item.thisYearPlanEntrustedNumber}}</div>
        <div class="column"><span>上年度实际委托利用处置量:</span>{{item.lastYearActualEntrustedNumber}}</div>
        <div class="column"><span>单位:</span>{{item.unit}}</div>
      </div>
    </div>
  </div>

  <el-footer style="padding: 0px;height: 60px;">
    <Footer />
  </el-footer>
</template>


<script>

import {getCompanyDeclare} from "@/api/companyInfo";
import Footer from "@/components/Footer";

export default {
  name: 'Info',
  components: {
    Footer
  },
  data(){
    return {
      companyInformation: {},
      companyEntrustList: {},
      companyInventoryList: {},
      companyStorageList: {},
      companyWasteList: {},
    }
  },
  created(){
    this.paramId = this.$route.query.d;
    if(!this.paramId){
      this.$message({
        type: "error",
        message: "二维码信息读取错误"
      })
      return;
    }else{
      this.getDetail(this.paramId);
    }
  },

  methods:{

    getDetail(dataId) {
      let data = {
        "companyId": dataId,
      };
      getCompanyDeclare(data).then(response=>{
        if (response.code === '0') {
          this.companyInformation = response.data.companyInformation;
          this.companyEntrustList = response.data.companyEntrustList;
          this.companyInventoryList = response.data.companyInventoryList;
          this.companyStorageList = response.data.companyStorageList;
          this.companyWasteList = response.data.companyWasteList;
        }

      });
    },

  }
}
</script>

<style scoped>

.itemTitle {
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  padding: 10px; /* 内边距 */
  border-bottom: 1px solid #ccc; /* 边框 */
}
.itemContent {
  font-size: 12px;
  margin: 10px;
  border-radius: 5px;
  box-shadow: 0 0 20px #ddd;
}


.column {
  font-size: 12px;
  flex: 1; /* 两列等宽 */
  padding: 10px; /* 内边距 */
  border-bottom: 1px solid #ccc; /* 边框 */
}
  /* 适用于移动端的CSS样式 */
  table {
    font-size: 12px;
    border-collapse: collapse;
  }

  th, td {
    border: 1px solid #ddd;
    font-size: 12px;
    padding: 8px;
    text-align: center;
  }

  th {
    background-color: #f2f2f2;
    text-align: center;
  }
  .foot_record{
    bottom: 0;
  }
</style>
