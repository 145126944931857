import request from '@/utils/request';

//列表
export function companyInfoList(data) {
  return request({
    url : '/companyInfo/companyInfoList',
    method : 'post',
    data : data
  })
}

//新增
export function createCompanyInfo(data) {
  return request({
    url : '/companyInfo/createCompanyInfo',
    method : 'post',
    headers: {'Content-Type':'application/x-www-form-urlencoded'},
    data : data
  })
}
//修改
export function updateCompanyInfo(data) {
  return request({
    url :'/companyInfo/updateCompanyInfo',
    headers: {'Content-Type':'application/x-www-form-urlencoded'},
    method : 'post',
    data : data
  })
}


//删除
export function deleteCompanyInfo(data) {
  return request({
    url : '/companyInfo/deleteCompanyInfo',
    method : 'post',
    data : data
  })
}
//批量删除
export function batchDeleteCompanyInfo(data) {
  return request({
    url : '/companyInfo/batchDeleteCompanyInfo',
    method : 'post',
    data : data
  })
}
//扫码填报信息
export function getCompanyDeclare(data) {
  return request({
    url : '/companyInfo/getCompanyDeclare',
    method : 'post',
    data : data
  })
}
